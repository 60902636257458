@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, #ffffff, #00539C); */
  background-image: linear-gradient(to left, #00539C, #072641);
  /* background-image: linear-gradient(to left, #0B1F35, rgb(1, 7, 24)); */
}
